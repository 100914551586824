import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import FeatureGrid from "components/ui/base/FeatureGrid";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import PlainHeaderWithCarousel from "components/ui/base/PlainHeaderWithCarousel";
import RegisterForCustomerSatisfaction from "components/ui/extended/RegisterForCustomerSatisfaction";
import Customers from "components/ui/extended/Customers";
import ReadPost from "components/ui/base/ReadPost";
import TestimonialWithImageDaniele from "components/ui/extended/TestimonialWithImageDaniele";
import NumberSteps from "components/ui/base/NumberSteps";
import VideoPreview from "components/ui/base/VideoPreview";
import FullWidthImage from "components/ui/base/FullWidthImage";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { getTrackedRegistrationUrl } from "utils/tracking";

interface SurveysPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: SurveysPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.features.surveys.seo.title")}
        description={t("pages.features.surveys.seo.description")}
        image={{
          relativePath: "meta/in-app-chat-surveys-templates.jpg",
          alt: "In-App Surveys ",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.features.surveys.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.features.surveys.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
        ]}
      />

      <PlainHeaderWithCarousel
        title={t("pages.features.surveys.title")}
        description={t("pages.features.surveys.description")}
        ctaRegister={{ show: true }}
        titleMaxWidth={700}
        descriptionMaxWidth={500}
        images={[
          {
            relativePath:
              "pages/features/surveys/survey-software-survey-template-1.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/features/surveys/survey-software-survey-template-2.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/features/surveys/survey-software-survey-template-3.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/features/surveys/survey-software-survey-template-4.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/features/surveys/survey-software-survey-template-5.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/features/surveys/survey-software-survey-template-6.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/features/surveys/survey-software-survey-template-7.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/features/surveys/survey-software-survey-template-8.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/features/surveys/survey-software-survey-template-9.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/features/surveys/survey-software-survey-template-10.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/features/surveys/survey-software-survey-template-11.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/features/surveys/survey-software-survey-template-12.jpg",
            alt: "Customerly",
          },
        ]}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "In-App Surveys",
            path: "/features/surveys/",
          },
        ]}
      />

      <ReadPost
        title={"6 Essential Customer Satisfaction Metrics to Track"}
        path={
          "/blog/customer-satisfaction/2020/12/14/6-essential-customer-satisfaction-metrics-to-track/"
        }
      />

      <TestimonialWithImageDaniele />

      <NumberSteps
        title={t("pages.features.surveys.steps.title")}
        elements={[1, 2, 3, 4].map((index) => {
          return {
            title: t(`pages.features.surveys.steps.elements.${index}.title`),
            description: t(
              `pages.features.surveys.steps.elements.${index}.description`
            ),
          };
        })}
      />

      <ImageWithDescription
        id={"advanced-targeting"}
        title={t("pages.features.surveys.1.title")}
        description={t("pages.features.surveys.1.subtitle")}
        image={{
          relativePath:
            "pages/features/surveys/choose-business-intelligence-crm-contact-segmentation.jpg",
          alt: t("pages.features.surveys.1.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <FeatureGrid
        id={"surveys-templates"}
        title={t("pages.features.surveys.templates.title")}
        centered={false}
        alternativeColors={true}
        sections={[
          "sales",
          "ui",
          "personas",
          "trials",
          "idea",
          "users",
          "features",
          "pricing",
          "key",
        ].map((index) => {
          return {
            title: t(`pages.features.surveys.templates.${index}.title`),
            description: t(
              `pages.features.surveys.templates.${index}.description`
            ),
            links: [
              {
                text: t(`pages.features.surveys.templates.${index}.cta`),
                path: getTrackedRegistrationUrl(
                  t(`pages.features.surveys.templates.${index}.cta`),
                  location.pathname
                ),
              },
            ],
          };
        })}
        button={{
          text: t("pages.features.surveys.templates.cta"),
          path: getTrackedRegistrationUrl(
            t("pages.features.surveys.templates.cta"),
            location.pathname
          ),
        }}
      />

      <ImageWithDescription
        title={t("pages.features.surveys.2.title")}
        description={t("pages.features.surveys.2.subtitle")}
        image={{
          relativePath:
            "pages/features/surveys/in-app-csat-survey-template.jpg",
          alt: t("pages.features.surveys.2.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <VideoPreview
        id={"goto-question"}
        title={t("pages.features.surveys.3.title")}
        paragraph={t("pages.features.surveys.3.subtitle")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath:
              "pages/features/surveys/survey-go-to-question-logic.mp4",
          },
        ]}
      />

      <FullWidthImage
        id={"reports"}
        title={t("pages.features.surveys.4.title")}
        description={t("pages.features.surveys.4.subtitle")}
        image={{
          relativePath:
            "pages/features/surveys/in-app-survey-software-reports.jpg",
          alt: t("pages.features.surveys.4.title"),
        }}
      />

      <VideoPreview
        id={"user-level-answer"}
        title={t("pages.features.surveys.5.title")}
        paragraph={t("pages.features.surveys.5.subtitle")}
        storiesPosition={"right"}
        stories={[
          {
            relativePath: "pages/features/surveys/survey-answers.mp4",
          },
        ]}
        cta={{
          text: t("pages.features.surveys.5.cta"),
          link: "/features/crm/",
        }}
      />

      <RegisterForCustomerSatisfaction />

      <Customers />
    </GlobalWrapper>
  );
};

export const sureveysQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/in-app-chat-surveys-templates.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
